<template>
  <sdPageHeader title="Suppliers"> </sdPageHeader>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Suppliers search">
          <a-form :model="searchForm" layout="vertical">
            <a-row :gutter="25">
              <a-col :span="6">
                <a-form-item ref="label" name="label" label="Label">
                  <a-input
                    v-model:value="searchForm.label"
                    placeholder="Label"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item ref="code" name="code" label="Code">
                  <a-input v-model:value="searchForm.code" placeholder="Code" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item
                  ref="designation_commerciale"
                  name="designation_commerciale"
                  label="Description in Invoice"
                >
                  <a-input
                    v-model:value="searchForm.designation_commerciale"
                    placeholder="Description in Invoice"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="6" class="button-container">
                <a-button
                  class="btn ant-btn-lg client-form-submit"
                  outlined
                  type="primary"
                  @click="onSubmit"
                >
                  <span>Search</span>
                </a-button>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button
                  @click="clearFilters"
                  class="btn-outlined ant-btn-lg client-form-submit"
                  outlined
                >
                  <span>Clear Filters</span>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </a-col></a-row
    >

    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Suppliers">
          <div>
            <TableWrapper class="table-responsive">
              <a-table
                :columns="columns"
                :dataSource="supplierData"
                :loading="isLoading"
              >
              </a-table>
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import axios from "axios";
import { notification } from "ant-design-vue";

import { defineComponent, ref, reactive } from "vue";
import { Main, TableWrapper } from "../styled";
const columns = [
  {
    title: "Label",
    dataIndex: "libelle",
    key: "libelle",
  },
  {
    title: "Description in Invoice",
    dataIndex: "designation_commerciale",
    key: "designation_commerciale",
  },
  {
    title: "Supplier",
    dataIndex: "fournisseur_libelle",
    key: "fournisseur_libelle",
  },
  {
    title: "Country",
    dataIndex: "pays_expediteur",
    key: "pays_expediteur",
  },
  {
    title: "Contact",
    dataIndex: "expediteur_tel",
    key: "expediteur_tel",
  },
  {
    title: "Address ",
    dataIndex: "expediteur_adresse",
    key: "expediteur_adresse",
  },
  {
    title: "File number",
    dataIndex: "numero_dossier",
    key: "numero_dossier",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Partner label",
    dataIndex: "libellePartenaire",
    key: "libellePartenaire",
  },
];
const Products = defineComponent({
  name: "Products",
  components: {
    Main,
    TableWrapper,
  },

  setup() {
    var supplierData = ref([]);
    var isLoading = ref(false);
    const clearFilters = () => {
      searchForm.code = "";
      searchForm.label = "";
      supplierData.value = [];
    };
    // #endregion

    // #region filters
    var searchForm = reactive({
      layout: "vertical",
      label: "",
      code: "",
    });
    const searchFormRef = ref([]);

    const onSubmit = async () => {
      if (
        searchForm.label.trim() === "" &&
        searchForm.code.trim() === "" &&
        searchForm.designation_commerciale.trim() === ""
      ) {
        notification.warning({
          message: "You need at least one filter criteria",
        });
      } else {
        isLoading.value = true;

        try {
          const response = await axios.get("https://st.sttsud.tn/index.php", {
            params: {
              option: "com_tktransit",
              task: "nomenclatureservice.getDetailNomenclatureAndDetailPartenaireByLibelleNomenclature",
              libelle_nomenclature: searchForm.label,
              code_nomenclature: searchForm.code,
              designation_commerciale: searchForm.designation_commerciale,
            },
          });

          supplierData.value = response.data;
        } catch (error) {
          notification.error({
            message: "Problem while searching data.",
          });
        } finally {
          isLoading.value = false;
        }
      }
    };

    // #end region
    return {
      columns,
      searchForm,
      searchFormRef,
      clearFilters,
      onSubmit,
      supplierData,
      isLoading,
    };
  },
});
export default Products;
</script>
<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
  height: 38px;
}
.button-container {
  align-self: center !important;
  .ant-btn {
    height: 38px;
    width: 100%;
  }
}
</style>
